let _gaIsInit = false;
let _trackPageRef = null;

/**
 * [ initGA ]
 */
export function initializeGA ({ ga_id }){
  if(!_gaIsInit) {
    // eslint-disable-next-line
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  }

  let fieldObject = {
    'allowLinker': true
  };

  window.ga( 'create', ga_id, 'auto', fieldObject );
  window.ga( 'require', 'linker' );
  window.ga( 'send', 'pageview');

  _gaIsInit = true;
}

/**
 * [ logEvent ]
 * category: string
 * action: string
 * customLabel: string|undefined
 */
export function logEvent({ category, action }, customLabel ){
  // missing params? do nothing - action can be empty string
  if( !Boolean( category ) || !Boolean( action ) ) return;

  // if ga not yet set, try again in a bit
  if( !_gaIsInit ){
    window.setTimeout( function(){
      logEvent.apply( null, { category, action }, customLabel );
    }.bind({ args: arguments }), 500 );

    return;
  }

  // form label
  const label = typeof customLabel === 'string' ? customLabel : `${category} : ${action}`;

  // send event
  window.ga( 'send', 'event', category, action, label );
}
/**
 * [ trackPage ]
 * pathName: string (required)
 * modifier: string
 */
export const trackPage = function( pathName ){
  // debounce when ga is not yet init
  if( !_gaIsInit ){
    clearTimeout( _trackPageRef );

    _trackPageRef = setTimeout( function(){
      trackPage.call( null, pathName );
    }.bind({ args: arguments }), 500 );

    return;
  }

  logEvent( { category: 'Page View', action: pathName} );

}.bind({ lastView: null });

export const checkClickEvent = (evt) => {
  const anchorTarget = evt.target.closest('a');

  if ( anchorTarget !== null ) {
    let action = evt.target.tagName === 'A' ? evt.target.textContent : anchorTarget.id;
    let label = anchorTarget.href;
    logEvent({category: 'click', action: action}, label);
  }
};

export const addClickListener = () => {
  document.addEventListener('click', checkClickEvent, false);
};


export const removeClickListener = () => {
  document.removeEventListener('click', checkClickEvent, false);
};
