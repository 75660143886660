import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { Link } from "../../components/Link";
import { isTrue } from 'util/helpers';

export function Landing({ data, stylesData, children }) {
  const { copy, image, cta } = data;
  const styles = styleSheet({ params: stylesData });

  return (
    <section className={css(styles.landing)}>

      {image.src &&
      <img className={css(styles.image)} src={image.src} alt={image.alt}/>}

      {copy.headline &&
      <h1 dangerouslySetInnerHTML={{__html: copy.headline}} className={css(styles.headline)}/>}

      <hr className={css(styles.separator)}/>

      {copy.description &&
      <h2 dangerouslySetInnerHTML={{__html: copy.description}} className={css(styles.description)}/>}

      {cta && <div className={css(styles.ctaGroupContainer)}>
        {cta.filter(ctaData => ctaData.image && ctaData.url).map((ctaData, index) =>
            <div key={index} className={css(styles.ctaContainer)}>
              <img src={ctaData.image} alt={ctaData.alt}/>
              <div>
                <h3 dangerouslySetInnerHTML={{__html: ctaData.title}}/>
                <p className={css(styles.ctaDescription)} dangerouslySetInnerHTML={{__html: ctaData.description}}/>
              </div>

              { isTrue(ctaData.active) ?
                <Link {...ctaData} copy={ <button>{ctaData.button}</button> } /> :
                <button aria-disabled="true" className={css(styles.disabled)}>{ctaData.button}</button> }
              <p className={css(styles.ctaRules)} dangerouslySetInnerHTML={{__html: ctaData.abbreviated_rules}}/>
            </div>
        )}
      </div>}

    </section>
  )
}
