import { StyleSheet } from 'aphrodite/no-important';
import {children, media} from "../../util/styles";

export const styleSheet = ({ params }) => StyleSheet.create({
  container: {
    maxWidth: 970,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '-webkit-justify-content': 'center',
    margin: '0 auto',
    padding: '10px',

    [media.tablet]: {
      '-webkit-justify-content': 'flex-end'
    }
  },

  label: {
    marginRight: 20,
    ...params.label
  },

  facebook: {
    marginLeft: 10,
    [children('svg')]: {
      ...params.facebook
    },

    [children('path')]: {
      fill: params.facebook.fill,
    }
  },

  twitter: {
    [children('svg')]: {
      ...params.twitter
    },

    [children('path')]: {
      fill: params.twitter.fill,
    }
  }
})
