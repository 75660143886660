import React, { useEffect } from 'react';
import { loadPymChild, PYM_PARENT_ID } from 'util/pym';

export function TelescopeProduct({ data, addChildListeners, loaderOptions }) {
  useEffect(() => {
    const scrollY = window.scrollY;
    loadPymChild(data.settings.url, loaderOptions);
    window.scrollTo(0, scrollY);

    if (addChildListeners) addChildListeners();

    return () => {
      window.Telescope.removeApplication(PYM_PARENT_ID);
    }
  }, [data.settings.url]);

  return (
    <div id={PYM_PARENT_ID} />
  )
}
