export const PYM_PARENT_ID = 'telescope-app';

export function loadPymChild(url, options = {}) {
  if (!url) return;

  window.Telescope.loadApplication({
    parentId: PYM_PARENT_ID,
    url: url,
    allowFullScreen: true,
    allowScrollToElementTop: false,
    allowStoredPosition: true,
    allowScrollToChildPosition: true,
    ...options
  });
}
