import React from "react";
import {css} from "aphrodite";
import {styleSheet} from "../Share/style";
import {isTrue} from "../../util/helpers";
import {FacebookIcon, TwitterIcon} from "../../util/icons";

export function Share({data, stylesData}) {
    const {settings, copy} = data;
    const styles = styleSheet({params: stylesData});
    const tweet = copy.tweet !== '' ? `text=${encodeURIComponent(copy.tweet)}` : '';
    const urlTwitter = copy.url !== '' ? `url=${encodeURIComponent(copy.url)}` : '';
    const urlFacebook = copy.url !== '' ? `u=${encodeURIComponent(copy.url)}` : '';

    return (
        <div className={css(styles.container)}>
            { (isTrue( settings.display_facebook ) || isTrue( settings.display_twitter )) &&
            <p className={css(styles.label)} dangerouslySetInnerHTML={{__html: copy.label}}/>}

            { isTrue( settings.display_twitter ) &&
            <div className={css(styles.twitter)}>
                <a target='_blank'
                   id='Share_twitter'
                   href={`https://twitter.com/intent/tweet?${tweet}&${urlTwitter}`}>
                    <TwitterIcon/>
                </a>
            </div> }

            { isTrue( settings.display_facebook ) &&
                <div className={css(styles.facebook)}>
                    <a target='_blank'
                       id='Share_facebook'
                       href={`https://www.facebook.com/sharer/sharer.php?${urlFacebook}`}>
                        <FacebookIcon/>
                    </a>
                </div> }
        </div>
    )
}
