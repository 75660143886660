import React from 'react';
import {css} from 'aphrodite/no-important';
import {styleSheet} from './style';
import {Link} from 'components/Link';
import {FacebookIcon, InstagramIcon, TikTokIcon, TwitterIcon, YoutubeIcon} from "../../util/icons";

export function Footer({data, stylesData}) {
    const {links, logo, legal, social} = data;
    const styles = styleSheet({params: stylesData});
    const socialIcons = {
        "instagram": <InstagramIcon/>,
        "tiktok": <TikTokIcon/>,
        "facebook": <FacebookIcon/>,
        "twitter": <TwitterIcon/>,
        "youtube": <YoutubeIcon/>
    };

    return (
        <footer className={css(styles.footer)}>
            <div className={css(styles.container)}>

                {logo.image &&
                <div className={css(styles.logo)}>
                    {logo.url ?
                        <a href={logo.url} target="_blank" rel="noreferrer">
                            <img src={logo.image} alt={logo.alt}/>
                        </a> :
                        <img src={logo.image} alt={logo.alt}/>
                    }
                </div>}



                {links && <div className={css(styles.linksContainer)}>
                    {links.filter(link => link.url && link.copy).map(link =>
                        <Link className={css(styles.link)} key={link.copy} {...link} />
                    )}
                </div>}

                <div className={css(styles.rightContainer)}>
                    {legal &&
                    <p className={css(styles.legal)} dangerouslySetInnerHTML={{ __html: legal }} />}

                    {social && socialIcons && <div className={css(styles.socialContainer)}>
                        {social.map(link =>
                            <Link key={link.type} id={`Footer_${link.type}`}
                                  copy={socialIcons[link.type]} {...link} />
                        )}
                    </div>}
                </div>

            </div>
        </footer>
    )
}
