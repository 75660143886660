export const DEFAULT_WID = '0180a02963d3027f';

export const ROUTES = {
  HOME: '/',
  CLOSED: '/closed',
  ERROR: '/error',
  VOTE: '/vote',
  CHEER: '/cheer',
  FAN_RULES: '/fan-favorite-rules',
  CHEER_RULES: '/cheer-moment-rules'
};

export const API_GEO = '/api/getregion.json';

// Storage
export const STORAGE = {
  THEME: 'hub_theme',
  AGE_GATE: 'shop_age_gate'
};

// Keyboard
export const KEYS = {
  DOWN: 40,
  ESCAPE: 27,
  LEFT: 37,
  RIGHT: 39,
  TAB: 9,
  UP: 38,
};


// Date
export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const MIN_YEAR = 1920;
