import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_GEO } from 'util/constants';

function useGeo(countries) {
  const [ inRegion, setInRegion ] = useState(null);
  const [ data, setData ] = useState({});

  const handleGeo = useCallback(async () => {
    const geoData = await fetchGeo();
    const isInRegion = geoData.geoheaders && ( countries.length? checkIfInRegion(geoData.geoheaders.country, countries) : true );

    setInRegion(isInRegion);
    setData(geoData.geoheaders);
  }, [countries])

  useEffect(() => {
    if (countries === undefined) return;

    handleGeo()
  }, [countries, handleGeo]);

  return { data, inRegion }
};

function fetchGeo() {
  return axios
    .get(API_GEO)
    .then((res) => { return res.data })
    .catch(err => {
      return null
  })
}

function checkIfInRegion(userCountry, allowedCountries) {
  if (!userCountry || !allowedCountries) { return null }

  const countries = allowedCountries.split(',').map(item => item.trim());

  return countries.indexOf(userCountry) >= 0;
}

export default useGeo;
