import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useEventListener from '@telescope/react-hooks/useEventListener';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { isTrue } from 'util/helpers';
import { Link } from 'components';
import {MenuButton} from '../MenuButton';
import {ArrowDownIcon} from "../../util/icons";
import {ROUTES} from "../../util/constants";

export function Header({ data, stylesData, hideNavigation }) {
  const { settings, logo, abc_logo, live_time, dropdown_label, dropdown_links, navigation_links } = data;
  const styles = styleSheet({ params: stylesData });
  const [ activeRules, setActiveRules ] = useState(false);
  const [ hamburgerDropdown, setHamburgerDropdown ] = useState(false);
  const location = useLocation();
  const buttonRef = useRef();
  const hamburgerRef = useRef();
  useEventListener('click', handleClick);

  useEffect(() => {
      const isActive = dropdown_links.find(link => location.pathname === link.url);
      setActiveRules(!!isActive);
  }, []);

  useEffect(() => {
      if ( hamburgerDropdown ) {
          document.getElementById('App').classList.add('no-scroll');
      } else {
          document.getElementById('App').classList.remove('no-scroll');
      }
  }, [hamburgerDropdown]);

  function handleClick(e) {
    if ( hamburgerDropdown && e.target.tagName === 'A' ) {
      setHamburgerDropdown(false);
    }

    if ( activeRules && e.target.tagName === 'A' && e.target.parentElement.id !== 'openDropdown' ) {
        setActiveRules(false);
    }

  }

  const desktopMenu = (
      <>
        <div className={css(styles.linksContainer)}>
          { navigation_links.filter(link => link.url).map(link => {
              if ( hideNavigation && link.url !== ROUTES.HOME) return '';
              if ( !isTrue(link.active) ) { return <span className={css(styles.link)}>{link.copy}</span>; }

              let isActive;
              if ( hideNavigation && ( location.pathname === ROUTES.ERROR || location.pathname === ROUTES.CLOSED ) ) {
                  isActive = link.url === ROUTES.HOME;
              } else {
                  isActive = location.pathname === link.url;
              }
              return <Link className={css(styles.link, isActive && styles.active)} key={link.url} { ...link } />
          }) }
        </div>

        { isTrue(settings?.display_dropdown) &&
        <button aria-haspopup="true" ref={buttonRef}
                className={css(styles.dropdownToggle, activeRules && styles.active)}>
          {dropdown_label} <ArrowDownIcon/>

            { isTrue(settings?.display_dropdown) &&
            <div className={css(styles.dropdown)} id='openDropdown'>
                { dropdown_links.filter(link => link.url).map(link => {
                    const isActive = location.pathname === link.url;
                    return <Link className={css(styles.dropdownLink, isActive && styles.dropdownLinkActive)}
                                 onClick={() => setActiveRules(true)}
                                 key={link.url} { ...link }/>
                }) }
            </div> }

        </button>}


      </>
  );

  const mobileMenu = (
      <div className={css(styles.hamburgerMenu)}>
        <button aria-haspopup="true" aria-expanded={hamburgerDropdown} ref={hamburgerRef}
                onClick={() => setHamburgerDropdown(!hamburgerDropdown)} className={css(styles.hamburgerToggle)}>
          <MenuButton isOpen={hamburgerDropdown}
                      strokeWidth={1}
                      color={'#fff'}
                      transition={{ type: "spring", stiffness: 260, damping: 15 }}
                      width={25}
                      height={16}
                      aria-label={`${hamburgerDropdown? 'close' : 'open'} dropdown menu`}/>
        </button>

        <div className={css(styles.hamburgerDropdown, hamburgerDropdown && styles.openHamburgerDropdown)}>
          { navigation_links.filter(link => link.url).map(link => {
            if ( hideNavigation && link.url !== ROUTES.HOME) return '';
            if ( !isTrue(link.active) ) { return <span className={css(styles.link)}>{link.copy}</span>; }

            let isActive;
            if ( hideNavigation && ( location.pathname === ROUTES.ERROR || location.pathname === ROUTES.CLOSED ) ) {
                isActive = link.url === ROUTES.HOME;
            } else {
                isActive = location.pathname === link.url;
            }
            return <Link className={css(styles.link, isActive && styles.active)} key={link.url} { ...link } />
          }) }
          { dropdown_links.filter(link => link.url).map(link => {
            const isActive = location.pathname === link.url;
            return <Link className={css(styles.link, isActive && styles.active)} key={link.url} { ...link }/>
          }) }
        </div>
      </div>
  );

  return (
    <header className={css(styles.header)}>
      <div className={css(styles.container)}>

        <div className={css(styles.logoContainer)}>
          { logo.image &&
          <figure className={css(styles.logo)}>
            <img src={logo.image} alt={logo.alt} />
          </figure>  }

          { abc_logo.image &&
          <figure className={css(styles.abcLogo)}>
              <img src={abc_logo.image} alt={abc_logo.alt} />
          </figure>  }

          { live_time.image &&
          <figure className={css(styles.liveTime)}>
              <img src={live_time.image} alt={live_time.alt} />
          </figure>  }
        </div>

        { isTrue(settings.display_navigation) &&
        <div className={css(styles.navContainer)}>

          { desktopMenu }

          { mobileMenu }

        </div> }

      </div>
    </header>
  )
}
