import { StyleSheet } from 'aphrodite/no-important';
import { children, media } from 'util/styles';

export const styleSheet = ({ params }) => StyleSheet.create({
  footer: {
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '0 15px',
    width: 1000,
    maxWidth: '100%',
    margin: 'auto',
    ...params.container,

    [media.tablet]: {
      ...params.container.tablet
    },

    [media.desktop]: {
      ...params.container.desktop
    }
  },

  container: {
    margin: '0 auto',
    maxWidth: '1320px',
    height: 74,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [media.tablet]: {
      height: 46,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },

  linksContainer: {
    marginBottom: 6,

    [media.tablet]: {
      marginBottom: 0
    }
  },

  link: {
    fontSize: 12,
    color: '#000',
    ...params.link,

    ':not(:last-child)': {
      marginRight: 30,
    },

    [media.tablet]: {
      ...params.link.tablet
    },

    [media.desktop]: {
      ...params.link.desktop
    }
  },

  rightContainer: {
    display: 'flex',
    flexFlow: 'column-reverse',
    [media.tablet]: {
      flexFlow: 'row',
      alignItems: 'center'
    }
  },

  socialContainer: {
    marginBottom: 6,

    [media.tablet]: {
      marginLeft: 10,
      marginBottom: 0,
    },

    [children('a')]: {
      [media.tablet]: {
        margin: '0 2px'
      },
    },

    [children('svg')]: {
      width: 22,
      height: 22,
      margin: '0 5px',

      [media.tablet]: {
        width: 18,
        height: 18,
        margin: 0
      }
    },

    [children('path')]: {
      fill: params.social.fill
    }
  },

  legal: {
    fontSize: 12,
    lineHeight: 1.5,
    ...params.legal,

    [media.tablet]: {
      ...params.legal.tablet
    },

    [media.desktop]: {
      ...params.legal.desktop
    },

    [children('a')]: {
      textDecoration: 'underline',
      color: '#fff'
    }
  },

  logo: {
    width: 60,
    height: 60,
    marginBottom: 20
  }
});
