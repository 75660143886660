export const CloseIcon = ({className}) => {
    return (
        <span className='close-icon'>
      <svg height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg' className={className}>
        <g fill='none' stroke='#000' strokeLinecap='round' strokeWidth='4'>
          <path d='m2 2.5 25.5 25.5'/>
          <path d='m27.5 2.5-25.5 25.5'/>
        </g>
      </svg>
    </span>
    );
};

export const DownloadIcon = ({className}) => {
    return (
        <span className='download-icon'>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29.98" viewBox="0 0 29 29.98" className={className}>
        <path d="M25.46,19.1V26H4.51V19.1h-4V28a2,2,0,0,0,2,2h25a2,2,0,0,0,2-2V19.1h-4Z"
              transform="translate(-0.49 0)"/>
        <path
            d="M14.62,18.43l-5.76-7s-0.88-.83.07-0.83h3.25V0.49S12,0,12.79,0h4.57a0.44,0.44,0,0,1,.52.42v10h3c1.15,0,.28.87,0.28,0.87s-4.9,6.51-5.59,7.19A0.64,0.64,0,0,1,14.62,18.43Z"
            transform="translate(-0.49 0)"/>
      </svg>
    </span>
    );
};


export const SelectIcon = ({className}) => {
    return (
        <span className={className}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 240.8 361.8">
      <g>
        <path id="Expand_More" d="M217.7,216.8l-97.3,97.7l-97.3-97.7c-4.7-4.7-12.1-4.7-16.8,0c-4.6,4.7-4.6,12.2,0,16.9
          L112,339.7l0,0l0,0c4.6,4.7,12.1,4.7,16.8,0l105.7-106.1c4.6-4.7,4.6-12.2,0-16.9C229.8,212.1,222.3,212.1,217.7,216.8z"/>
        <g>
          <path id="Expand_More_1_" d="M23.1,152.4l97.3-97.7l97.3,97.7c4.7,4.7,12.1,4.7,16.8,0c4.6-4.7,4.6-12.2,0-16.9
            L128.8,29.4l0,0l0,0c-4.6-4.7-12.1-4.7-16.8,0L6.3,135.5c-4.6,4.7-4.6,12.2,0,16.9C11,157.1,18.5,157.1,23.1,152.4z"/>
        </g>
      </g>
      </svg>
    </span>
    )
};

export const FacebookIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Hub" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="4_Oscars_StandardVote_Desktop_Login" transform="translate(-1190.000000, -87.000000)">
                    <g id="Group-10" transform="translate(1068.000000, 87.000000)">
                        <g id="Group-9" transform="translate(79.750000, 0.000000)">
                            <g id="Facebook" transform="translate(42.250000, 0.000000)">
                                <path
                                    d="M17,0 C7.616,0 0,7.616 0,17 C0,24.752 5.168,31.28 12.24,33.32 L12.24,21.216 L9.452,21.216 L9.452,16.728 L12.24,16.728 L12.24,14.008 L12.24,12.036 C12.24,12.036 12.172,8.432 16.932,7.344 C20.264,6.596 23.46,7.684 23.46,7.684 L22.848,12.24 C22.848,12.24 18.02,10.472 18.02,14.076 L18.02,16.796 L22.712,16.796 L22.304,21.284 L18.02,21.284 L18.02,34 C26.928,33.456 34,26.044 34,17 C34,7.616 26.384,0 17,0"
                                    id="SVGID_1_"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
};

export const TwitterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path
                d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
        </svg>
    )
};

export const TikTokIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 2859 3333">
            <path
                d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"/>
        </svg>
    )
};

export const InstagramIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
        </svg>
    )
};

export const YoutubeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 45" width="18" height="18">
            <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"/>
        </svg>
    )
};

export const ArrowDownIcon = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 330 330">
            <path id="XMLID_225_"
                  d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
        </svg>
    )
};
