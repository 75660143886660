import { isMobile } from 'react-device-detect';
import clone from 'lodash/clone';

export const media = {
  mobile: '@media (min-width: 320px)',
  mobileLg: '@media (min-width: 500px)',
  tablet: '@media (min-width: 768px)',
  desktop: '@media (min-width: 1440px)',
  hover: '@media (hover: hover) and (pointer: fine)'
};

export const children = (child) => {
  return `:nth-child(1n) ${child}`
}

export const MAX_WIDTH = 980;
export const HEADER_HEIGHT = {
  MOBILE: 50,
  TABLET: 70,
  DESKTOP: 70
};


export const normalizeStyles = function (styles) {
  const o = clone(styles, true);

  if (isObject(o)) {
    const n = {};

    Object.keys(o)
      .forEach((k) => {
        if(k === 'background_image' && o[k]) {
          if(o[k] !== 'none' && o[k].indexOf('url(') === -1){
            o[k] = `url(${o[k]})`;
          }
        }

        if (k === 'hover' && !isMobile) {
          const key = ':hover';
          o[key] = o[k];
          k = key;
        }

        if (k === 'active') {
          const key = ':active';
          o[key] = o[k];
          k = key;
        }

        if(o[k] !== '') {
          n[toCamel(k)] = normalizeStyles(o[k]);
        }
      });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return normalizeStyles(i);
    });
  }

  return o;
};

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};
