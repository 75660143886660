import {StyleSheet} from 'aphrodite/no-important';
import { media } from 'util/styles';

export const styleSheet = () => StyleSheet.create({

    documentContainer: {
        padding: '0 11px',
        margin: 'auto',
        fontSize: 16,
        lineHeight: 1.4,

        [media.tablet]: {
            maxWidth: 970
        },

        [media.desktop]: {
            maxWidth: 1000
        }
    }
})
