import { StyleSheet } from 'aphrodite/no-important';
import { media, children } from 'util/styles';

export const styleSheet = ({ params }) => StyleSheet.create({
  landing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto 10',
    position: 'relative',
    ...params.background,

    [media.tablet]: {
      ...params.background.tablet
    },
  },

  image: {
    width: '40%',
    maxWidth: 490,
    margin: '3% auto 0.5%',

    [media.tablet]: {
      margin: '5.5% auto 4%',
    },

    [media.desktop]: {
      width: '50%',
      margin: '33px auto 20px',
    }
  },

  headline: {
    fontSize: 12,
    ...params.headline,

    [media.tablet]: {
      fontSize: 30,
      ...params.headline.tablet,
    },

    [media.desktop]: {
      fontSize: 36,
      ...params.headline.desktop
    }
  },

  separator: {
    ...params.separator,

    [media.tablet]: {
      ...params.separator.tablet
    }
  },

  description: {
    ...params.description,

    [media.tablet]: {
      ...params.description.tablet
    },

    [media.desktop]: {
      ...params.description.desktop
    }
  },

  ctaGroupContainer: {
    display: 'flex',
    margin: '20px 0',
    flexWrap: 'wrap'
  },

  ctaContainer: {
    width: '90%',
    minHeight: 243,
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    [media.mobileLg]: {
      minHeight: 290
    },

    [media.tablet]: {
      width: 'calc( 50% - 4vw )',
      maxWidth: 370,
      minHeight: 330
    },

    [children('h3')]: {
      fontSize: 16,
      fontWeight: 400,
      marginTop: 10,

      [media.tablet]: {
        fontSize: 18
      }
    },

    [children('img')]: {
      width: '100%'
    },

    [children('button')]: {
      fontSize: 14,
      minWidth: 125,
      width: 'auto',
      padding: '0 15px',
      height: 32,
      margin: '5px 0 15px 0',
      fontFamily: '"Futura PT", sans-serif',

      [media.tablet]: {
        minWidth: 168,
        height: 44,
        marginBottom: 15
      }
    },

    [':first-child']: {
      marginBottom: 25,
      ...params.cta[0],
      borderWidth: 4,

      [media.tablet]: {
        margin: '0 2vw',
        borderWidth: 8
      },

      [children('button')]: {
        ...params.cta[0].button
      }
    },

    [':last-child']: {
      marginBottom: 15,
      ...params.cta[1],
      borderWidth: 4,


      [media.tablet]: {
        margin: '0 2vw',
        borderWidth: 8
      },

      [children('button')]: {
        ...params.cta[1].button
      }
    }
  },

  ctaDescription: {
      fontSize: 12,
      fontStyle: 'italic',
      fontFamily: '"Hoefler Text", sans-serif',
      fontFeatureSettings: '"dlig" 1, "swsh" 1',
      margin: '5px',

      [media.tablet]: {
        fontSize: 16
      }
  },

  ctaRules: {
    fontSize: 11,
    fontWeight: 300,
    margin: '0px 30px 15px 30px',

    [children('a')]: {
      textDecoration: 'underline'
    }
  },

  disabled: {
    pointerEvents: 'none'
  },

  buttonsContainer: {
    marginBottom: 25,

    [media.tablet]: {
      marginBottom: 40
    },

    [media.desktop]: {
      marginBottom: 48
    },
  },

  button: {
    display: 'inline-block',
    letterSpacing: -0.5,
    minHeight: 27,
    minWidth: 111,
    ...params.global.buttons,

    [media.tablet]: {
      minHeight: 61,
      minWidth: 258,
      ...params.global.buttons.tablet,
    },

    [media.desktop]: {
      minHeight: 65,
      minWidth: 284,
      ...params.global.buttons.desktop,
    }
  }
});
