import { StyleSheet } from 'aphrodite/no-important';
import { media } from 'util/styles';

export const styleSheet = ({ params }) => StyleSheet.create({
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '60px 20px',
    ...params.background,

    [media.tablet]: {
      ...params.background?.tablet
    }
  },

  headline: {
    fontSize: 12,
    marginBottom: 10,
    ...params.headline,

    [media.tablet]: {
      fontSize: 30,
      ...params.headline.tablet
    },

    [media.desktop]: {
      fontSize: 36,
      ...params.headline.desktop
    }
  },

  description: {
    marginBottom: 20,
    ...params.description,

    [media.tablet]: {
      ...params.description.tablet
    },

    [media.desktop]: {
      ...params.description.desktop
    }
  }
})
