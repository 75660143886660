import { StyleSheet } from 'aphrodite/no-important';
import { media } from 'util/styles';

export const styleSheet = ({ params }) => {
  return StyleSheet.create({
    app: {
      backgroundColor: '#000',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      width: '100%',

      [media.tablet]: {
      },

      [media.desktop]: {

      }
    },

    main: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 2500,
      margin: '0 auto',
      width: '100%',
      backgroundSize: '100% auto',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      flexGrow: 1,
      position: 'relative',
      overflow: 'hidden',
      ...params.main,


      [media.tablet]: {
        backgroundSize: '100% auto',
      },

      [media.desktop]: {
        backgroundSize: '1440px auto',
      }
    }
  });

}
