import React from 'react';
import { css } from 'aphrodite/no-important';
import { style } from './style';

export const LoadingSpinner = ({
  size = 65,
  strokeWidth = 3,
  fill = '#000',
  position = 'center',
}) => {
  const styles = style({
    duration: '1.4s',
    offset: 187,
    color: fill,
    position,
  });

  return (
    <div className={css(styles.container)}>
      <svg
        className={css(styles.spinner)}
        width={`${size}px`}
        height={`${size}px`}
        viewBox={`0 0 66 66`}
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          className={css(styles.path)}
          fill='none'
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          cx='33'
          cy='33'
          r='30'
        />
      </svg>
    </div>
  );
};
