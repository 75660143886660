import React from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import {Share} from "../Share";

export function PageLayout(props) {
  const { data, copy, shareData } = props;
  const styles = styleSheet({ params: data });


  return (
    <>
    <section className={css(styles.container)}>
      <div className={css(styles.pageContainer)}>
        <Share data={shareData.data} stylesData={shareData.styles}/>

        { (copy.headline || copy.description) &&
        <div className={css(styles.copyContainer)}>
          { copy.headline &&
          <h1 className={css(styles.headline)} dangerouslySetInnerHTML={{ __html: copy.headline }} /> }

          <hr className={css(styles.separator)}/>

          { copy.description &&
          <p className={css(styles.description)} dangerouslySetInnerHTML={{ __html: copy.description }} /> }
        </div> }

        <div className={css(styles.childrenContainer)}>
          { props.children }
        </div>

        { copy.legal &&
        <p className={css(styles.legal)} dangerouslySetInnerHTML={{ __html: copy.legal }} /> }
      </div>
    </section>
    </>
  )
}
