import { StyleSheet } from 'aphrodite/no-important';

export const style = ({ duration, offset, color, position }) => {
  const dashKeyframes = {
    '0%': { strokeDashoffset: offset },
    '50%': {
      strokeDashoffset: offset / 4,
      transform: 'rotate(135deg)',
    },
    '100%': {
      strokeDashoffset: offset,
      transform: 'rotate(450deg)',
    },
  };

  const rotatorKeyframes = {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(270deg)' },
  };

  let container = {};

  if (position === 'inline') {
    container = {
      display: 'inline',
    };
  }
  if (position === 'center') {
    container = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    };
  }
  if (position === 'fullscreen') {
    container = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }

  return StyleSheet.create({
    spinner: {
      animationName: rotatorKeyframes,
      animationDuration: duration,
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },

    path: {
      stroke: color,
      strokeDasharray: offset,
      strokeDashoffset: 0,
      transformOrigin: 'center',
      animationName: dashKeyframes,
      animationDuration: duration,
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
    },
    container,
  });
};
