export const isTrue = (value) => {
  return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export function getQSPs() {
  const qsps = Object.create(null);

  window.location.search
  .slice(1)
  .split('&')
  .forEach(item => {
    const [key, value = ''] = item.split('=');

    qsps[key] = value;
  });

  return qsps;
}

export const getQueryParamByName = (name, url) => {
  const regexS = '[\\?&#]' + name + '=([^&#]*)';
  const regex = new RegExp(regexS);

  const results = regex.exec( url || window.location.search ) ||
    regex.exec( window.location.hash );

  if ( results === null ) { return ''; }
  return decodeURIComponent( results[ 1 ].replace( /\+/g, ' ' ) );
};


export function loadScript(src) {
  return new Promise(function(resolve, reject) {
    const s = document.createElement('script');
    let r = false;
    s.type = 'text/javascript';
    s.src = src;
    s.async = true;
    s.onerror = function(err) {
      reject(err, s);
    };
    s.onload = s.onreadystatechange = function() {
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true;
        resolve();
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });
}

export const insertFonts = (url) => {
  if (!url || document.head.querySelectorAll(`link[href='${url}']`).length) {
    return;
  }

  const fontLink = document.createElement('link');
  document.head.appendChild(fontLink);
  fontLink.rel = 'stylesheet';
  fontLink.href = url;
}
