import { StyleSheet } from 'aphrodite/no-important';
import { children, media, HEADER_HEIGHT } from 'util/styles';

export const styleSheet = ({ params }) => StyleSheet.create({
  header: {
    height: `${HEADER_HEIGHT.MOBILE}px`,
    ...params.global,

    [media.tablet]: {
      height: `${HEADER_HEIGHT.TABLET}px`,
      ...params.global.tablet
    },

    [media.desktop]: {
      height: `${HEADER_HEIGHT.DESKTOP}px`,
      ...params.global.desktop
    }
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    position: 'relative',
    maxWidth: 980,
    width: '100%',
    margin: '0 auto',
    padding: '0 15px',
    ...params.container,

    [media.tablet]: {
      ...params.container.tablet
    },

    [media.desktop]: {
      ...params.container.desktop
    }
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 3
  },

  logo: {
    height: 22,
    margin: '0 6px 0 0',
    ...params.logo,

    [media.tablet]: {
      height: 32,
      marginRight: 12,
      ...params.logo.tablet
    },

    [media.desktop]: {
      marginRight: 10,
      ...params.logo.desktop
    }
  },

  abcLogo: {
    borderLeft: '1px solid #fff',
    padding: '0 5px 0 7px',
    boxSizing: 'content-box',
    height: 20,
    ...params.abcLogo,

    [media.tablet]: {
      padding: '0 8px 0 13px',
      height: 40,
      ...params.abcLogo.tablet
    }
  },

  liveTime: {
    height: 20,
    ...params.liveTime,

    [media.tablet]: {
      height: 30,
      ...params.liveTime.tablet
    }
  },

  navContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },

  dropdown: {
    display: 'none',
    ...params.dropdown,

    [media.tablet]: {
      display: 'none',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      bottom: 0,
      transform: 'translateY(100%)',
      textAlign: 'right',
      padding: '0px 15px 8px 15px',
      minWidth: 174,
      zIndex: 3,
      ...params.dropdown.tablet,

      [media.desktop]: {
        ...params.dropdown.desktop
      }
    }
  },

  dropdownToggle: {
    display: 'none',
    ...params.link,

    [media.tablet]: {
      height: '100%',
      display: 'block',
      fontSize: 14,
      ...params.link.tablet
    },

    [media.desktop]: {
      fontSize: 14,
      ...params.link.desktop
    },

    ':active': {
      textDecoration: 'none'
    },

    ':hover > #openDropdown': {
      display: 'flex !important',
    },

    ':hover': {
      color: params.activeLink.color,
      [children('path')]: {
        fill: params.activeLink.color
      }
    },

    [children('path')]: {
      fill: params.link.color
    }
  },

  dropdownLink: {
    padding: 10,
    width: 220,
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#000',
    boxSizing: 'border-box',

    ':hover': {
      ...params.activeLink
    },

    ':focus': {
      ...params.activeLink
    }
  },

  dropdownLinkActive: {
    ...params.activeLink
  },

  linksContainer: {
    display: 'none',

    [media.tablet]: {
      display: 'flex',
      alignItems: 'center',

      [children('a')]: {
        maxWidth: 140,
      }
    },

    [children('span')]: {
      cursor: 'default'
    }
  },

  link: {
    textAlign: 'center',
    marginRight: 10,
    ...params.link,

    [media.tablet]: {
      fontSize: 14,
      marginRight: 25,
      ...params.link.tablet
    },

    [media.desktop]: {
      fontSize: 14,
      ...params.link.desktop
    },

    ':active': {
      textDecoration: 'none'
    },

    ':hover': {
      ...params.activeLink
    }
  },

  active: {
    ...params.activeLink,

    [children('path')]: {
      fill: params.activeLink.color
    }
  },

  hamburgerMenu: {
    position: 'relative',
    [media.tablet]: {
      display: 'none'
    }
  },

  hamburgerToggle: {
    position: 'relative',
    zIndex: 3,
    paddingTop: 1,

    [media.tablet]: {
      marginRight: 20
    }
  },

  hamburgerDropdown: {
    display: 'flex',
    position: 'fixed',
    transition: 'transform .7s',
    backgroundColor: '#000',
    zIndex: 2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'translateX(100%)',
    flexDirection: 'column',
    paddingTop: 60,

    [media.tablet]: {
      display: 'none'
    },

    [children('a, span')]: {
      fontSize: 20,
      marginBottom: 40,

      [media.tablet]: {
        marginBottom: 0
      }
    }
  },

  openHamburgerDropdown: {
    transform: 'translateX(0)',
    transition: 'transform .7s'
  }
});
