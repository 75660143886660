import { StyleSheet } from 'aphrodite/no-important';
import { media, children } from 'util/styles';

export const styleSheet = ({ params }) => StyleSheet.create({
  container: {
    flexGrow: 1,
    ...params.container,
    [media.tablet]: {
      ...params.container.tablet
    },
    [media.desktop]: {
      ...params.container.desktop
    }
  },

  pageContainer: {
    marginTop: 22,
    position: 'relative',
    margin: '0 auto',
    minHeight: 500,
    alignSelf: 'start',
    width: '100%',
    padding: '11px 0',
    textAlign: 'left',

    [media.tablet]: {
      minHeight: 700,
      alignSelf: 'stretch',
    }
  },

  copyContainer: {
    marginBottom: 13,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 30px',
    ...params.subheader,

    [media.tablet]: {
      padding: '0 30px',
    },

    [media.desktop]: {
      padding: '0 50px',
    }
  },

  headline: {
    fontSize: 18,
    lineHeight: 1.4,
    ...params.headline,

    [media.tablet]: {
      fontSize: 30,
      lineHeight: 1.3,
      ...params.headline.tablet,
    },

    [media.desktop]: {
      fontSize: 42,
      ...params.headline.desktop,

      [children('br')]: {
        display: 'none'
      }
    }
  },

  separator: {
    ...params.separator,

    [media.tablet]: {
      ...params.separator.tablet
    }
  },

  description: {
    fontSize: 12,
    lineHeight: 1.3,
    ...params.description,

    [media.tablet]: {
      fontSize: 18,
      ...params.description.tablet
    },

    [media.desktop]: {
      fontSize: 24,
      lineHeight: 1.1,
      ...params.description.desktop
    }
  },

  legal: {
    ...params.legal,

    [media.tablet]: {
      maxWidth: 970,
      ...params.legal?.tablet
    },

    [media.desktop]: {
      ...params.legal?.desktop
    },

    [children('a')]: {
      textDecoration: 'underline'
    }
  },

  childrenContainer: {
    ...params.childrenContainer,

    [media.tablet]: {
      ...params.childrenContainer?.tablet
    }
  }
})
