import React from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import useCMS from "@telescope/react-hooks/useCMS";

export function DocumentWidget({ wid, polling }) {

  const [ dataCMS ] = useCMS(wid, {
    pollingFrequency: polling, alwaysReturnCachedResponse: true });

  const styles = styleSheet();

  return (
    <section className={css(styles.documentContainer)}>
      { dataCMS?.data?.document &&
      <p dangerouslySetInnerHTML={{ __html: dataCMS?.data?.document }} /> }
    </section>
  )
}
