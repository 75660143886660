import React, { useEffect, useState } from 'react';
import { Redirect, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import useCMS from '@telescope/react-hooks/useCMS';
import { DEFAULT_WID, ROUTES } from 'util/constants';
import { isTrue, getQSPs } from 'util/helpers';
import useGeo from 'hooks/useGeo';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { LoadingSpinner, Header, Footer, PageLayout } from 'components';
import { TelescopeProduct, Landing, Error } from 'pages';
import useNormalizedStyles from 'hooks/useNormalizedStyles';
import { insertFonts } from 'util/helpers';
import { storageFactory } from 'util/storage';
import 'focus-visible';
import {addClickListener, initializeGA, removeClickListener, trackPage} from "../../util/googleAnalytics";
import {DocumentWidget} from "../../pages/DocumentWidget";

const localStore = storageFactory(localStorage);

export function App() {
  const { wid = DEFAULT_WID, sid } = getQSPs();
  const [ pollingFrequency, setPollingFrequency ] = useState(5000);
  const [ cmsData ] = useCMS(wid, { sid, pollingFrequency });

  const errorNavigationPages = [ ROUTES.ERROR, ROUTES.CLOSED, ROUTES.CHEER_RULES, ROUTES.FAN_RULES ];

  // Default styles data
  const [ stylesCms ] = useCMS(cmsData?.text.snapshot_settings.styles_wid, {
    pollingFrequency: cmsData?.text.snapshot_settings.styles_polling_frequency, alwaysReturnCachedResponse: true });
  const stylesData = useNormalizedStyles(stylesCms?.text);
  const shareData = {
    data: cmsData?.text?.share,
    styles: stylesData?.share
  };

  const geo = useGeo(cmsData?.settings.countries);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    addClickListener();

    return () => {
      removeClickListener();
    }

  }, []);

  // Polling Frequency
  useEffect(() => {
    if (!cmsData) return;

    setPollingFrequency(cmsData.text.snapshot_settings.styles_polling_frequency || 5000);
    initializeGA({
      ga_id: cmsData.settings.google_analytics
    });

  }, [ cmsData ]);

  // Normalize styles
  useEffect(() => {
    if (!stylesData) return;

    insertFonts(stylesData.global.font.fontImport);

  }, [ stylesData ]);

  useEffect(() => {
    if ( cmsData && stylesData && document.body.className === 'preload' ) {
      setTimeout(() => {
        document.body.className = '';
      }, 500);
    }
  }, [ cmsData && stylesData ]);

  useEffect(() => {
    // Tracking pages
    const pageName = location.pathname.replace(/-/g, ' ').replace('/', '');
    trackPage(pageName === '' ? 'Home' : pageName);

    // Scroll new page to top
    window.scrollTo(0, 0);

  }, [location.pathname]);

  const addChildListeners = () => {
    // products listeners
  };

  if (!cmsData || !stylesData || geo.inRegion === null) {
    return <LoadingSpinner />
  }

  const styles = styleSheet({ params: stylesData });

  const isWindowClosed = !isTrue(cmsData.settings.window_status);

  if ( !geo.inRegion && !errorNavigationPages.includes(history.location.pathname) ) {
    history.push(ROUTES.ERROR)
  }

  if ( isWindowClosed && !errorNavigationPages.includes(history.location.pathname) ) {
    history.push(ROUTES.CLOSED)
  }

  return (
    <div className={css(styles.app)} id='App'>
      { isTrue(cmsData.text.header.settings.display) &&
        <Header data={cmsData.text.header}
                stylesData={stylesData.header}
                hideNavigation={!geo.inRegion || isWindowClosed}/> }

      <main className={css(styles.main)}>

        <Switch>

          <Route path={ROUTES.ERROR}>
            <Error data={cmsData.text.geo} stylesData={stylesData.error} />
          </Route>

          <Route path={ROUTES.CLOSED}>
            {!isWindowClosed ? <Redirect to={ROUTES.HOME} /> :
            <Error data={cmsData.text.closed} stylesData={stylesData.error} /> }
          </Route>

          <Route exact path={ROUTES.VOTE}>
            <PageLayout data={stylesData.vote} copy={cmsData.text.vote.copy.page_header} shareData={shareData}>
              <TelescopeProduct data={cmsData.text.vote} addChildListeners={addChildListeners} />
            </PageLayout>
          </Route>

          <Route exact path={ROUTES.CHEER}>
            <PageLayout data={stylesData.cheer} copy={cmsData.text.cheer.copy.page_header} shareData={shareData}>
              <TelescopeProduct data={cmsData.text.cheer} addChildListeners={addChildListeners} />
            </PageLayout>
          </Route>

          <Route exact path={ROUTES.FAN_RULES}>
            <PageLayout data={stylesData.rules} copy={cmsData.text.fan_rules.copy.page_header} shareData={shareData}>
              <DocumentWidget wid={cmsData?.text.snapshot_settings.fan_rules_wid}
                              polling={cmsData?.text.snapshot_settings.document_polling_frequency}/>
            </PageLayout>
          </Route>

          <Route exact path={ROUTES.CHEER_RULES}>
            <PageLayout data={stylesData.rules} copy={cmsData.text.cheer_rules.copy.page_header} shareData={shareData}>
              <DocumentWidget wid={cmsData?.text.snapshot_settings.cheer_rules_wid}
                              polling={cmsData?.text.snapshot_settings.document_polling_frequency}/>
            </PageLayout>
          </Route>

          <Route path={ROUTES.HOME}>
            <>
            <Landing data={{ ...cmsData.text.landing, events: { ...cmsData.text.landing.events, agenda: cmsData.data }}}
                    stylesData={{ ...stylesData.landing, global: stylesData.global }}>
            </Landing>
            </>
          </Route>

        </Switch>

      </main>

      { isTrue(cmsData.text.footer.settings.display) &&
        <Footer data={cmsData.text.footer} stylesData={stylesData.footer} /> }

    </div>
  );
}
