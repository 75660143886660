import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import clone from 'lodash/clone';

const useNormalizedStyles = (data) => {
  const [ styles, setStyles ] = useState(null);

  useEffect(() => {
    if (!data) return;

    const normalized = normalizeStyles(data);
    setStyles(normalized);
  }, [data]);

  const normalizeStyles = function (styles) {
    const o = clone(styles, true);

    if (isObject(o)) {
      const n = {};

      Object.keys(o)
        .forEach((k) => {
          if(k === 'background_image' && o[k]) {
            if(o[k] !== 'none' && o[k].indexOf('url(') === -1){
              o[k] = `url(${o[k]})`;
            }
          }

          if (k === 'hover' && !isMobile) {
            const key = ':hover';
            o[key] = o[k];
            k = key;
          }

          if (k === 'active') {
            const key = ':active';
            o[key] = o[k];
            k = key;
          }

          if(o[k] !== '') {
            n[toCamel(k)] = normalizeStyles(o[k]);
          }
        });

      return n;
    } else if (isArray(o)) {
      return o.map((i) => {
        return normalizeStyles(i);
      });
    }

    return o;
  };

  return styles;
}

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};


export default useNormalizedStyles;
