import React from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';

export function Error({ data, stylesData }) {
  const { copy } = data;
  const styles = styleSheet({ params: stylesData });

  return (
    <section className={css(styles.error)}>

      { copy.headline &&
          <h1 dangerouslySetInnerHTML={{ __html: copy.headline }} className={css(styles.headline)}/> }

      { copy.description &&
          <p dangerouslySetInnerHTML={{ __html: copy.description }} className={css(styles.description)}/> }

    </section>
  )
}
